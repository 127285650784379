import image1 from '../images/2-After.webp';
import image2 from '../images/Elisha.webp';
import image3 from '../images/IMG-3523.webp';
import image4 from '../images/IMG-5124.webp';
import image5 from '../images/IMG_0374.webp';
import image6 from '../images/IMG_1206.webp';
import image7 from '../images/IMG_20160502_122001963.webp';
import image8 from '../images/IMG_20160316_111910009.webp';
import image9 from '../images/IMG_20161005_134659000_HDR.webp';
import image10 from '../images/IMG_20171122_101223.webp';
import image11 from '../images/IMG_20190515_074823517.webp';
import image12 from '../images/Mikhail&Solomio.webp';
import image13 from '../images/IMG_20130813_100548.webp';
import image14 from '../images/IMG_20150906_195855296.webp';
import image15 from '../images/IMG_20160415_102444848.webp';
import image16 from '../images/IMG_20161004_190720991.webp';
import image17 from '../images/IMG_20170619_122803411_HDR.webp';
import image18 from '../images/IMG_20170920_094424916.webp';
import image19 from '../images/IMG_20171017_111604974_HDR.webp';
import image20 from '../images/IMG_20171208_160409264.webp';
import image21 from '../images/IMG_20190530_122158392.webp';

export const photosArray = [
        {
        source:
        `${image1}`
        },
        {
        source:
        `${image2}`
        },
        {
        source:
        `${image3}`
        },
        {
        source:
        `${image4}`
        },
        {
        source:
        `${image5}`
        },
        {
        source:
        `${image6}`
        },
        {
        source:
        `${image7}`
        },
        {
        source:
        `${image8}`
        },
        {
        source:
        `${image9}`
        },
        {
        source:
        `${image10}`
        },
        {
        source:
        `${image11}`
        },
        {
        source:
        `${image12}`
        },
        {
        source:
        `${image13}`
        },
        {
        source:
        `${image14}`
        },
        {
        source:
        `${image15}`
        },
        {
        source:
        `${image16}`
        },
        {
        source:
        `${image17}`
        },
        {
        source:
        `${image18}`
        },
        {
        source:
        `${image19}`
        },
        {
        source:
        `${image20}`
        },
        {
        source:
        `${image21}`
        },                        
    ];
