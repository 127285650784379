import styled from 'styled-components';
import theme from '../utils/theme';

const Header = () => (
  <Wrapper>
    <NavWrapper>
      <Name>Ministry Thru Music</Name>
    </NavWrapper>
  </Wrapper>
);

export default Header;

const NavWrapper = styled.div`
display: flex;
justify-content: center;
  @media only screen and (max-width: ${theme.bp.medium}px) {
    margin: 30px 20px 30px;
  }
`;

const Name = styled.div`
  font-size: 50px;
  font-weight: 200;
  color: #dccd44;
  justify-content: center;
  @media only screen and (max-width: ${theme.bp.medium}px) {
    font-size: 35px;
    font-weight: 400;
  }
`;

const Wrapper = styled.div` 
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: ${theme.bp.medium}px) {
    display: flex;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.3) inset;
  }
`;
