import React from 'react';
import styled from "styled-components";
import {Header, Footer} from '../components'
import theme from '../utils/theme';
import { ReactPhotoCollage } from "react-photo-collage";
import { photosArray } from '../components/photoCollage';
import useWindowDimensions from '../hooks/getWindowDimensions';
import profilePic from '../images/Baggech_James_00055a.webp';
import donationQR from '../images/qr_bIYbLBfgL9Z30Hm8ww.webp';

const Home = () => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 820;

  const settings1 = {
    width: "100%",
    height: (isMobile) ? ["200px"] : ["320px"],
    layout: (isMobile) ? [3] : [5],
    photos: photosArray,
    showNumOfRemainingPhotos: false
  };

  const settings2 = {
    width: "100%",
    height: (isMobile) ? ["200px"] : ["320px"],
    layout: (isMobile) ? [3] : [6],
    photos: [photosArray[6],photosArray[7],photosArray[8],photosArray[9],photosArray[10],photosArray[11]],
    showNumOfRemainingPhotos: false
  };

  const settings3 = {
    width: "100%",
    height: (isMobile) ? ["200px"] : ["220px"],
    layout: (isMobile) ? [3] : [6],
    photos: [photosArray[20],photosArray[19],photosArray[18],photosArray[17],photosArray[16],photosArray[15]],
    showNumOfRemainingPhotos: false
  };

  const handleCheckout = () => {
    window.location.href = "https://donate.stripe.com/bIYbLBfgL9Z30Hm8ww";
  }

return (
  <Wrapper>
    <Header />
    <AboveTheFold>
      <ReactPhotoCollage {...settings1}/>
      <MissionStatment>Promoting worship and glorifying <Highlight>God</Highlight> and the <Highlight>Lord Jesus Christ</Highlight> using musical Instruments.</MissionStatment>
      <ReactPhotoCollage {...settings2}/>  
    </AboveTheFold>
    <Main>
      <AboutSection>
        <AboutText>
          <Heading>Purpose</Heading> 
          To find musical Instruments through donations and provide a means to get them to missionaries and individuals who can’t afford them, who are members of Gospel preaching churches and schools.
 To provide training in the care of the musical instrument sent, that the maximum benefit of the instrument is attained.
        </AboutText>
        <AboutText>
          <Heading>Outreach</Heading> 
          <SubHeading>Countries that have received Musical Instruments from Ministry Thru Music</SubHeading>
        Ukraine, Egypt, Russia, Cambodia, Nepal, South Africa, French Guiana, Estonia, Papua New Guinea, Australia, Brazil, Chile, Peru, Romania, The Solomon Islands, Kazakhstan, Georgia, Mexico
          <SubHeading>States Receiving Instruments</SubHeading>
        Indiana, Montana, Illinois, Florida, Tennessee, North Carolina, Michigan, Pennsylvania, Ohio, Oklahoma
        </AboutText>
        <AboutText>
          <Heading>History</Heading> 
        Since 2005 Ministry Thru Music/MTM Musical Instruments has sought donated new and used musical instruments to send churches, missionaries, orphanages around the world.
We have donated musical instruments in 17 countries around the world being used to glorify our Lord Jesus Christ.
        </AboutText>
      </AboutSection>
    </Main>
    <MainSection>
      <Personal>
        <Founder src={profilePic}/>
        <Testimony>
          <TestimonyHeading>James Baggech</TestimonyHeading>
          When I was 10, I was given the opportunity to be involved with the summer
District 7 Performing Arts Program at Kelly High School.
There is where I started my love of playing low brass musical instruments.
 The man who started me off on the right foot was Charles Guse, tubist of the Lyric Opera and Chicago Symphony. 
This was to be a 5-year relationship of mentoring and teaching, till my family moved to Bolingbrook, Illinois during my sophomore year of high school.
In Bolingbrook, God had me meet someone who would be my best friend during my time in high school, Tony.
At Tony’s home one night while I was visiting, he introduced me to his cousin, Butch, from Iowa.
During this visit Butch presented me with the Gospel of Our Lord Jesus Christ.
There in Tony’s living room, sitting on a white piano bench, I received Jesus Christ as my personal savior, June 16, 1976.
        </Testimony>
      </Personal>
    </MainSection>
    <AboveTheFold>
      <MissionStatment2>Won’t you give the “Winds a Mighty Voice” for the Gospel to be proclaimed.</MissionStatment2>
    </AboveTheFold>
    <CTA>
    <CTASubHeading>"I was influenced by people who cared enough to invest time and energy in my life as a boy.
     As a man, I now proclaim the Lord Jesus Christ as a dividend of those who invested in my life."</CTASubHeading>
      <CallToActionSection>
        <CallToAction>
          <CTAParagraph>Will you donate and invest in someone’s Christian growth?</CTAParagraph>
          {!isMobile && (
            <>
              <p>Scan code to donate</p>
              <QrImage src={donationQR}/>
              <p>or click the button below</p>
            </>
          )}
          <Button onClick={handleCheckout}>Donate</Button>
        </CallToAction>
        <CallToAction>
          <CTAContainer>
            <CTAParagraph>Will you invest in someone’s future by donating your unused musical instrument?</CTAParagraph>
            <CTAParagraph>Donate a musical instrument for God’s Glory.</CTAParagraph>
          </CTAContainer>
          <EmailContainer>
            <p>Click the email link below to contact MTM about donating an instrument</p>
            <Email href='mailto:mtminstr@pm.me'>mtminstr@pm.me</Email>
          </EmailContainer>
        </CallToAction>
      </CallToActionSection>
    </CTA>
    <ReactPhotoCollage {...settings3}/>
    <Footer />
  </Wrapper>
)}

export default Home;

const QrImage = styled.img`
  display: flex;
  height: 200px;
  width: 200px;
  margin: auto;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  @media only screen and (max-width: ${theme.bp.medium}px) {
    display: none;
  } 
`;

const CTAParagraph = styled.p`
  font-size: 20px;
  margin: 0 50px 20px;
  font-weight: 400;
  @media only screen and (max-width: ${theme.bp.medium}px) {
    margin: 0 50px 50px;
    font-size: 18px;
    font-weight: 300;
  } 
`;

const Email = styled.a`
  color: black;
  text-decoration: underline;
  font-weight: 200;
  font-size: 22px;
  &:hover {
    cursor: pointer;
  }
`;

const CTAContainer = styled.div`
  flex: 1;
`;

const EmailContainer = styled.div`
  flex: 0.575;
`;

const Button = styled.a`
  font-size: 18px;
  align-items: center;
  background-color: #dccd44;
  border-radius: 50px;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 250px;
  margin: 30px auto;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  transition: all .2s ease-in-out;
  @media only screen and (max-width: ${theme.bp.medium}px) {
    width: 250px;
    height: 50px;
    margin: 10px auto 30px;
  }

  &:hover {
    cursor: pointer;
    transform:scale(1.05,1.05) perspective(1px);
    -webkit-transform:scale(1.05,1.05);
    -moz-transform:scale(1.05,1.05);
  }
`;

const AboutText = styled.p`
  color: whitesmoke;
  border-right: 1px white solid;
  font-weight: 200;
  font-size: 16px;
  border-radius: 3px;
  padding: 20px 30px 30px 30px;
  flex: 1;
  margin: 0px 10px;
  @media only screen and (max-width: ${theme.bp.medium}px) {
    flex-direction: column;
    font-size: 15px;
    margin: 10px 0;
    border-bottom: 1px white solid;
    border-right: 0;
    padding: 10px 10px 40px 10px;
    &:last-child {
    border-bottom: 0;
  }
  }
  &:last-child {
    border-right: 0;
  }
`;

const AboveTheFold = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const AboutSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 50px 30px;
  @media only screen and (max-width: ${theme.bp.medium}px) {
    flex-direction: column;
    margin: 20px 30px;
  }
`;

const CallToAction = styled.p`
  font-weight: 200;
  font-size: 22px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: ${theme.bp.medium}px) {
    margin: 20px 0;
    font-size: 16px;
  }
`;

const CallToActionSection = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto auto;
  @media only screen and (max-width: ${theme.bp.medium}px) {
    flex-direction: column;
    margin: 20px 30px;
  }
`;

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: whitesmoke;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.30), 0 6px 20px 0 rgba(0, 0, 0, 0.25) inset;
  @media only screen and (max-width: ${theme.bp.medium}px) {
    flex-direction: column;
  }
`;

const Personal = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 50px 150px 50px;
    @media only screen and (max-width: ${theme.bp.medium}px) {
    flex-direction: column;
    align-items: center;
    margin: 50px 30px 50px;
  }
`;

const Highlight = styled.span`
  color: #dccd44;
  font-size: 28px;
  font-weight: 400;
  @media only screen and (max-width: ${theme.bp.medium}px) {
    font-size: 16px;
    font-weight: bold;
  }
`;

const MainSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: whitesmoke;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.30), 0 6px 20px 0 rgba(0, 0, 0, 0.25) inset;
`;

const Main = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Founder = styled.img`
  height: 350px;
  width: 310px;
  border: 1px white solid;
  border-radius: 4px;
  @media only screen and (max-width: ${theme.bp.medium}px) {
    height: 300px;
    width: 260px;
  }
`;

const Testimony = styled.p`
  margin: 0 50px 0 120px;
  font-weight: 200;
  font-size: 18px;
  @media only screen and (max-width: ${theme.bp.medium}px) {
    margin: 20px 0;
    font-size: 16px;
  }
`;

const Heading = styled.h2`
  color: whitesmoke;
  margin: 0 0 30px;
`;

const TestimonyHeading = styled(Heading)`
  color: black;
  font-size: 30px;
  margin: 0 0 20px;
`;

const SubHeading = styled.h3``;

const CTASubHeading = styled(SubHeading)`
  margin: 80px 200px 80px;
  font-size: 24px;
  font-style: italic;
  @media only screen and (max-width: ${theme.bp.medium}px) {
    margin: 50px 100px 20px;
    font-size: 20px;
  }
`;

const MissionStatment = styled.span`
  color: whitesmoke;
  font-size: 28px;
  background-color: #461016;
  padding: 40px 0;
  margin: 1px 0;
  font-weight: 200;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.25) inset;
  @media only screen and (max-width: ${theme.bp.medium}px) {
    padding: 30px 20px;
    font-size: 18px;
    font-weight: bold;
  }
`;

const MissionStatment2 = styled(MissionStatment)`
  color: #dccd44;
  font-size: 28px;
  background-color: #461016;
  padding: 25px 0;
  font-weight: 200;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.25) inset;
  @media only screen and (max-width: ${theme.bp.medium}px) {
    padding: 30px 20px;
    font-size: 18px;
    font-weight: bold;
  }
`;

const Wrapper = styled.div`
  align-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
