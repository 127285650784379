const colors = {
    black: "#000000",
    white: "#ffffff",
  };
  
  const mappedColors = {
    backdropColor: `${colors.white}22`,
  
    borderColor: colors.black,
  
    headerBackground: colors.white,
    headerColor: colors.black,
  };
  
  const values = {
    // breakpoints
    bp: {
      xsmall: 640,
      small: 850,
      medium: 1124,
      large: 1300,
    },
  
    // layout
    layout: {
      maxWidth: 1200,
  
      small: {
        maxWidthPercentage: 85,
      },
      medium: {
        maxWidth: 800,
      },
      large: {
        maxWidth: 1000,
      },
    },
  };
  
  const theme = {
    colors: {
      ...colors,
      ...mappedColors,
    },
    ...values,
  };
  
  export default theme;
