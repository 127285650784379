import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Home from './pages/home.js';

function App() {
  return (
    <div className="App-Wrapper">
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>}/>
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
