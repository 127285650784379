import styled from 'styled-components';
import theme from '../utils/theme';

const Footer = () => (
  <Wrapper>
  <Content>
    <ContactSecton>
      <Heading>Contact MTM:</Heading>
      <Email href='mailto:mtminstr@pm.me'>mtminstr@pm.me</Email>
    </ContactSecton>
    {/* <LinkContainer>
    Links:
      <Link href="">Plum Creek Christian Academy</Link>
      <Link href="">Dyer Baptist Church</Link>
      <Link href="">Dyer Baptist Church</Link>
      <Link href="">Plum Creek Christian Academy</Link>
      <Link href="">Dyer Baptist Church</Link>
      <Link href="">Dyer Baptist Church</Link>
    </LinkContainer> */}
  </Content>
  <Credit>4TL Development</Credit>
  </Wrapper>
);

export default Footer;

const Email = styled.a`
  color: whitesmoke;
  text-decoration: underline;
  margin: 0 0 10px;
  font-weight: 200;
  font-size: 18px;
`;

const Link = styled.a`
  color: whitesmoke;
  font-weight: 400;
  font-size: 14px;
  margin: 5px 0;
  text-decoration: underline;  
  &:first-child {
    margin: 20px 0 0 0;
  }
`;

const LinkContainer = styled.div`
  font-weight: 400;
  font-size: 18px;
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  color: whitesmoke;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  width: 100%;
  margin: 10px 0 30px 0;
`;

const Heading = styled.h3`
  color: whitesmoke;
  font-weight: 400;
`;

const Credit = styled.text`
  color: whitesmoke;
  font-size: 10px;
  font-weight: 200;
`;

const ContactSecton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.footer`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 20px 0;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
  @media only screen and (max-width: ${theme.bp.medium}px) {
    width: 100%;
  }
`;
